import { ApolloError } from '@apollo/client';
import { Box, Button, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import { useEffect, useMemo, useState } from 'react';
import { FieldValues, FormProvider, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import FormBanner, {
  FormBannerType,
} from '~/base/components/FormBanner/FormBanner';
import FormInput from '~/base/components/FormInput';
import FormSelect from '~/base/components/FormSelect';
import LoadingIndicator from '~/base/components/LoadingIndicator';
import SectionTipCard from '~/base/components/SectionTipCard';
import SectionTitle from '~/base/components/SectionTitle/SectionTitle';
import {
  ArtistInput,
  CreateRecordingMutation,
  RecordingInput,
  RecordingType,
  UpdateRecordingInput,
  UpdateRecordingMutation,
  useCreateRecordingMutation,
  useGenresQuery,
  useRecordingByIdQuery,
  useUpdateRecordingMutation,
} from '~/types/generated/graphql';
import { Translator } from '~/types/Translator';
import ArtistSearchBar, { ArtistProps } from './ArtistSearchBar';

interface AddManualRecordingProps extends Translator {
  recordingIsrcList: Array<string>;
}

export default function AddManualRecording({
  t,
  recordingIsrcList,
}: AddManualRecordingProps) {
  const navigate = useNavigate();
  const { id: songId, rid: recordingId } = useParams();

  // Set properties for ISRC field if Add or Edit Recording
  let isrcProperties;
  if (recordingId) {
    isrcProperties = {
      disabled: true,
    };
  } else {
    isrcProperties = {
      required: {
        value: true,
        message: t(
          'page.add-recording.tabs.manual.recording.fields.isrc.required',
        ),
      },
      clearErrorOnFocus: true,
      displayRequired: true,
      pattern: {
        value: /^(?!CN|US-?S1Z)[A-Z]{2}-?[0-9A-Z]{3}-?[0-9]{2}-?[0-9]{5}$/,
        message: t(
          'page.add-recording.tabs.manual.recording.fields.isrc.validation',
        ),
      },
      validate: {
        isUniqueIsrc: (v: unknown) =>
          !recordingIsrcList.includes(v as string) ||
          t('page.add-recording.tabs.manual.recording.fields.isrc.duplicate'),
      },
    };
  }

  const [sortedGenreList, setSortedGenreList] = useState<
    { choiceId: string; choiceLabel: string }[]
  >([]);
  const [errorMessages, setErrorMessages] = useState<string[]>([]);
  const [wasArtistSelected, setWasArtistSelected] = useState(false);
  const [currentRecording, setCurrentRecording] = useState<
    RecordingType | undefined
  >(undefined);

  const { data: recordingData, loading } = useRecordingByIdQuery({
    variables: { id: Number(recordingId || '') },
    onCompleted: (res) => {
      // Reroute user back to Edit Song page for Spotify recordings
      if (res.recording?.spotifyTrackId) {
        navigate(`/songs/${songId}/edit`);
      }
      setCurrentRecording(res.recording as RecordingType);
      setWasArtistSelected(true);
    },
  });

  // React-hook-form
  const formMethods = useForm({
    mode: 'onBlur',
    defaultValues: useMemo(() => {
      return (recordingData?.recording as RecordingType) || {};
    }, [recordingData]),
    shouldUseNativeValidation: true,
  });

  const { data: genreList } = useGenresQuery();

  const [createRecordingMutation, { loading: saveRecording }] =
    useCreateRecordingMutation({
      fetchPolicy: 'no-cache',
    });

  const [updateRecordingMutation, { loading: updateRecording }] =
    useUpdateRecordingMutation({
      fetchPolicy: 'no-cache',
    });

  const handleAddCompleted = (res: CreateRecordingMutation) => {
    const addRecordingErrors = res.createRecording?.errors;

    if (addRecordingErrors) {
      const mutationErrors = addRecordingErrors.map(
        (err) => err?.error as string,
      );
      setErrorMessages(mutationErrors);
      window.scrollTo({ top: 0 });
    } else {
      navigate(`/songs/${songId}/edit`);
    }
  };

  const handleUpdateCompleted = (res: UpdateRecordingMutation) => {
    const updateRecordingErrors = res.updateRecording?.errors;

    if (updateRecordingErrors) {
      const mutationErrors = updateRecordingErrors.map(
        (err) => err?.error as string,
      );
      setErrorMessages(mutationErrors);
      window.scrollTo({ top: 0 });
    } else {
      navigate(`/songs/${songId}/edit`);
    }
  };

  const handleSubmitError = (error: ApolloError) => {
    setErrorMessages([error.message]);
    window.scrollTo({ top: 0 });
  };

  const handleFormSubmit = async (values: FieldValues) => {
    setErrorMessages([]);

    const artistsInput = values.artists.map(
      (artist: ArtistProps) =>
        ({
          id: artist?.id ? parseInt(artist?.id || '0', 10) : null,
          name: artist?.name as string,
        }) as ArtistInput,
    );

    if (await formMethods.trigger()) {
      if (currentRecording) {
        // Update recording
        const updateRecordingInput: UpdateRecordingInput = {
          recordingId: parseInt(values.id || '0', 10),
          name: values.name,
          artists: artistsInput as Array<ArtistInput>,
          genre: values.genre ? parseInt(values.genre || '0', 10) : undefined,
        };

        updateRecordingMutation({
          variables: { input: updateRecordingInput },
          onCompleted: handleUpdateCompleted,
          onError: handleSubmitError,
        });
      } else {
        // Create recording
        const recordingInput: RecordingInput = {
          name: values.name,
          isrc: values.isrc,
          artists: artistsInput as Array<ArtistInput>,
          songId: parseInt(songId || '0', 10),
          genre: values.genre ? parseInt(values.genre || '0', 10) : undefined,
        };

        createRecordingMutation({
          variables: { input: recordingInput },
          onCompleted: handleAddCompleted,
          onError: handleSubmitError,
        });
      }
    }
  };

  useEffect(() => {
    const genreSorted =
      genreList?.genres
        ?.map((edge) => ({
          choiceId: edge?.id || '',
          choiceLabel: edge?.name || '',
        }))
        .sort((a, b) => a.choiceLabel.localeCompare(b.choiceLabel)) || [];

    setSortedGenreList(genreSorted);
  }, [genreList]);

  useEffect(() => {
    formMethods.reset(currentRecording);
  }, [currentRecording]);

  return (
    <form
      data-testid="manual-add-recording-page"
      onSubmit={formMethods.handleSubmit(handleFormSubmit)}
    >
      {loading ? (
        <LoadingIndicator size={50} />
      ) : (
        <FormProvider {...formMethods}>
          <Grid container>
            <Grid item xs={12}>
              {errorMessages &&
                errorMessages.map((error) => {
                  return (
                    <FormBanner
                      text={error as string}
                      type={FormBannerType.ERROR}
                      sx={{ mb: '0.5rem' }}
                    />
                  );
                })}
            </Grid>
            <Grid item xs={12} md={9}>
              <Box sx={{ pr: { md: 3, xs: 0 } }}>
                <SectionTitle>
                  {t('page.add-recording.tabs.manual.recording.title')}
                </SectionTitle>
                <FormInput
                  id="recording-title"
                  label={t(
                    `page.add-recording.tabs.manual.recording.fields.recording-title.label`,
                  )}
                  name="name"
                  sx={{
                    width: '100%',
                    mb: 3,
                  }}
                  inputProps={{ 'data-testid': 'recording-title' }}
                  required={{
                    value: true,
                    message: t(
                      'page.add-recording.tabs.manual.recording.fields.recording-title.required',
                    ),
                  }}
                  clearErrorOnFocus
                  displayRequired
                />
                <FormInput
                  id="isrc-number"
                  label={t(
                    `page.add-recording.tabs.manual.recording.fields.isrc.label`,
                  )}
                  name="isrc"
                  sx={{
                    width: '100%',
                    mb: 3,
                  }}
                  inputProps={{ 'data-testid': 'isrc-input' }}
                  {...isrcProperties}
                />
                <FormSelect
                  id="genre"
                  label={t(
                    `page.add-recording.tabs.manual.recording.fields.genre.label`,
                  )}
                  name="genre"
                  sx={{
                    width: '100%',
                    mb: 3,
                  }}
                  options={sortedGenreList}
                />
              </Box>
            </Grid>
            <Grid item xs={12} md={3}>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'flex-end',
                  height: '100%',
                }}
              >
                <SectionTipCard
                  title={t('page.add-recording.tabs.manual.tips.isrc.title')}
                  color="blue"
                >
                  <Typography
                    variant="body1"
                    component="span"
                    dangerouslySetInnerHTML={{
                      __html: t(
                        'page.add-recording.tabs.manual.tips.isrc.content',
                      ) as string,
                    }}
                  />
                </SectionTipCard>
              </Box>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} md={9}>
              <Box sx={{ pr: { md: 3, xs: 0 } }}>
                <SectionTitle>
                  {t('page.add-recording.tabs.manual.artists.title')}
                </SectionTitle>
                <ArtistSearchBar
                  t={t}
                  handleArtistSelection={(bool) => {
                    setWasArtistSelected(bool);
                  }}
                />
              </Box>
            </Grid>
          </Grid>
          <Grid container sx={{ justifyContent: 'space-between', mt: '4rem' }}>
            <Button
              id="back-to-song-button"
              variant="outlined"
              onClick={() => navigate(`/songs/${songId}/edit`)}
            >
              {t(`page.add-recording.tabs.manual.buttons.back-edit-song`)}
            </Button>
            <Button
              id="save-and-back-button"
              disabled={
                saveRecording ||
                updateRecording ||
                !formMethods.formState.isValid ||
                !formMethods.formState.isDirty ||
                !wasArtistSelected
              }
              variant="contained"
              color="secondary"
              data-testid="button-submit"
              type="submit"
              name="button-submit"
            >
              {t(`page.add-recording.tabs.manual.buttons.save-recording`)}
            </Button>
          </Grid>
        </FormProvider>
      )}
    </form>
  );
}
