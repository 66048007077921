import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Box, Button, Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import FlagNameEnum from '~/base/enums/flagNameEnum';
import FlagTypeEnum from '~/base/enums/flagTypeEnum';
import useFeatureFlag from '~/base/hooks/useFeatureFlag';
import { currentSongtrustUserPersonaVar } from '~/cache';
import { useLoggedInSongtrustUserPersonaLazyQuery } from '~/types/generated/graphql';
import { Translator } from '~/types/Translator';

interface TrolleyTaxIframeProps extends Translator {
  id: string;
  title: string;
  src: string;
}

function TrolleyTaxIframe({ t, id, title, src }: TrolleyTaxIframeProps) {
  const navigate = useNavigate();

  const { hasFlag } = useFeatureFlag(
    FlagNameEnum.SHOW_TAX_WIDGET,
    FlagTypeEnum.FLAG,
  );

  // Function to re-poll loggedInUserPersona, call this after event emitted from trolley a
  // and update the reactive var so the user can navigate the site after the webhook is hit.
  const [reloadLoggedInUserPersona] = useLoggedInSongtrustUserPersonaLazyQuery({
    fetchPolicy: 'no-cache',
    pollInterval: 2000,
    onCompleted: (res) => {
      currentSongtrustUserPersonaVar(res);
    },
  });

  window.addEventListener('message', (e) => {
    const widgetEvent = e.data;
    if (widgetEvent.document) {
      const widgetHeight = widgetEvent.document.height;

      if (widgetEvent.event === 'document.height') {
        const iframeElement = document.getElementById(id);

        if (iframeElement) {
          iframeElement.style.height = `${widgetHeight}px`;
        }
      }
    } else if (widgetEvent.account || widgetEvent.taxForm) {
      // Call function to poll persona updates.
      reloadLoggedInUserPersona();
    }
  });

  return (
    <Grid item xs={12} md={9}>
      {/* pl and pr deviates from DashboardPage to compensatefor for padding inside 3rd party iframe */}
      <Box
        data-testid="tax-page-content"
        display="flex"
        flexDirection="column"
        sx={{
          pl: { md: '1rem' },
          pr: { md: '3rem' },
          pt: { xs: '2rem', md: '0' },
        }}
      >
        <iframe
          id={id}
          title={title}
          scrolling="no" // No better way of doing this consistently
          src={src}
          width="100%"
          style={{ border: 0, padding: 0 }}
        />
        {hasFlag && id === 'trolley-frame' && (
          <Button
            id="2024-tax-button"
            variant="outlined"
            sx={{ alignSelf: 'flex-end' }}
            onClick={() => navigate('/account/tax-documentation/2024')}
          >
            {t('sections.tax-information.buttons.2024-tax-info')}
            <ArrowForwardIcon sx={{ fontSize: '1.75rem', pl: '0.5rem' }} />
          </Button>
        )}
      </Box>
    </Grid>
  );
}

export default TrolleyTaxIframe;
