import { ApolloError, useReactiveVar } from '@apollo/client';
import {
  Button,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { isValidPhoneNumber } from 'libphonenumber-js';
import { Dispatch, SetStateAction, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import FormBanner from '~/base/components/FormBanner';
import { FormBannerType } from '~/base/components/FormBanner/FormBanner';
import FormInput from '~/base/components/FormInput';
import { currentSongtrustUserPersonaVar } from '~/cache';
import {
  ChannelEnum,
  useSendOptInMfaCodeMutation,
} from '~/types/generated/graphql';
import { Translator } from '~/types/Translator';

interface MfaFormStepOneProps extends Translator {
  setMfaStep: Dispatch<SetStateAction<number>>;
  handleCancel: () => void;
}

interface MfaFormValues {
  'mfa-phone-number': string | null | undefined;
  'mfa-code': string;
  'mfa-channel': ChannelEnum;
}

function MfaFormStepOne({ t, setMfaStep, handleCancel }: MfaFormStepOneProps) {
  const navigate = useNavigate();
  const loggedInUser = useReactiveVar(currentSongtrustUserPersonaVar);
  if (loggedInUser?.loggedInSongtrustUser?.mfaOptIn)
    navigate('/account/security');

  // State and mutation
  const [processError, setProcessError] = useState<string>('');
  const [sendOptInMfaCodeMutation, { loading }] = useSendOptInMfaCodeMutation({
    onCompleted: (data) => {
      if (data?.requestOptInMfaCode?.success) {
        setMfaStep(2);
      } else {
        const errorMessages =
          data?.requestOptInMfaCode?.errors
            ?.map((error) => error?.error)
            .join(' | ') || '';
        setProcessError(errorMessages);
      }
      window.scrollTo({ top: 0 });
    },
    onError: (error: ApolloError) => {
      setProcessError(error.message);
      window.scrollTo({ top: 0 });
    },
  });

  // Form Submission
  const { handleSubmit, setValue, getValues, watch } =
    useFormContext<MfaFormValues>();
  const onSubmit = (values: MfaFormValues) => {
    setProcessError('');
    sendOptInMfaCodeMutation({
      variables: {
        input: {
          optInPhoneNumber: values['mfa-phone-number'] || '',
          channel: values['mfa-channel'],
        },
      },
    });
  };

  const channel = watch('mfa-channel');

  // Handlers
  const handleSwapChannelClick = (e: React.MouseEvent) => {
    e.preventDefault();
    const currentChannel = getValues('mfa-channel');
    setValue(
      'mfa-channel',
      currentChannel === ChannelEnum.Sms ? ChannelEnum.Call : ChannelEnum.Sms,
    );
  };

  // Display
  const theme = useTheme();
  const xs = useMediaQuery(theme.breakpoints.only('xs'));

  return (
    <form id="mfaFormStepOne" onSubmit={handleSubmit(onSubmit)}>
      <Grid container item xs={12} sm={8.5}>
        <Grid item id="mfa-form-step-one-container">
          {processError && (
            <FormBanner text={processError} type={FormBannerType.ERROR} />
          )}
          {/* Header and body text */}
          <Typography variant="h1" data-testid="mfa-form-step-one-title">
            {t('sections.mfaVerificationStep1.title')}
          </Typography>
          {channel === ChannelEnum.Sms && (
            <Typography variant="body1" sx={{ mt: 4 }}>
              {t('sections.mfaVerificationStep1.body-sms')}
            </Typography>
          )}
          {channel === ChannelEnum.Call && (
            <Typography variant="body1" sx={{ mt: 4 }}>
              {t('sections.mfaVerificationStep1.body-call')}
            </Typography>
          )}
          {/* Phone number input */}
          <Typography variant="h6" sx={{ fontWeight: 'bold', mt: 2, pb: 0 }}>
            {t('sections.mfaVerificationStep1.label')}
          </Typography>
          <FormInput
            sx={{
              width: xs ? '100%' : '55%',
            }}
            id="mfa-phone-number"
            name="mfa-phone-number"
            required={{
              value: true,
              message: t(
                'sections.account-information.about-me.fields.phone.validation.required',
              ),
            }}
            inputProps={{ 'data-testid': 'mfa-phone-number' }}
            validate={{
              isValidPhoneNumber: (v) =>
                isValidPhoneNumber(v as string) ||
                t(
                  'sections.account-information.about-me.fields.phone.validation.invalid',
                ),
            }}
            isPhoneNumber
          />
        </Grid>

        {/* Actions */}
        <Grid
          container
          item
          xs={12}
          sx={{
            mt: 4,
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          {/* Swap channel to receive code */}
          <Grid item sm={6}>
            <Typography
              variant="h6"
              component="a"
              href="#"
              sx={{
                fontWeight: 'bold',
                color: 'black',
                textDecoration: 'underline',
                display: 'inline-block',
              }}
              onClick={handleSwapChannelClick}
              data-testid="mfa-form-step-one-swap-channel"
            >
              {channel === ChannelEnum.Sms &&
                t('sections.mfaVerificationStep1.call-instead')}
              {channel === ChannelEnum.Call &&
                t('sections.mfaVerificationStep1.sms-instead')}
            </Typography>
          </Grid>

          {/* Cancel/Verify */}
          <Grid
            item
            xs={12}
            sm={6}
            sx={{
              display: 'flex',
              justifyContent: xs ? 'space-between' : 'flex-end',
              gap: 2,
              mt: xs ? 2 : 0,
            }}
          >
            <Button
              data-testid="cancel-step1-button"
              variant="outlined"
              onClick={handleCancel}
              sx={{ width: xs ? '45%' : 'auto' }}
              disabled={loading}
            >
              {t('sections.mfaVerificationStep1.cancel')}
            </Button>
            <Button
              data-testid="verify-step1-button"
              variant="contained"
              color="secondary"
              type="submit"
              sx={{ width: xs ? '45%' : 'auto' }}
              disabled={loading}
            >
              {t('sections.mfaVerificationStep1.verify')}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
}

export default MfaFormStepOne;
