/* eslint-disable jsx-a11y/media-has-caption */
import { NotInterested } from '@mui/icons-material';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import EditIcon from '@mui/icons-material/Edit';
import PauseCircleOutlineIcon from '@mui/icons-material/PauseCircleOutline';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import { Box, Grid, Tooltip, Typography } from '@mui/material';
import React from 'react';
import { ArtistType } from '~/types/generated/graphql';
import { Translator } from '~/types/Translator';

interface RecordingItemProps extends Translator {
  id: string;
  name: string;
  artists: Array<ArtistType>;
  spotifyTrackId: string;
  spotifyPreviewUrl: string;
  currentTime: number;
  duration: number;
  playingRecordingId: string | null;
  handlePlayPauseClick: (id: string) => void;
  handleEditRecordingClick: (id: number) => void;
  handleDeleteRecordingClick: (id: number) => void;
  handleSeek: (id: string, event: React.MouseEvent<HTMLDivElement>) => void;
  formatTime: (seconds: number) => string;
  audioRef: React.Ref<HTMLAudioElement> | null; // Use the appropriate type for the audioRef
  handleAudioTimeUpdate: (id: string) => void;
  handleAudioLoadedMetadata: (id: string) => void;
  disabled: boolean;
}

export default function RecordingItem({
  id,
  name,
  artists,
  spotifyTrackId = '',
  spotifyPreviewUrl,
  currentTime,
  duration,
  playingRecordingId,
  handlePlayPauseClick,
  handleEditRecordingClick,
  handleDeleteRecordingClick,
  handleSeek,
  formatTime,
  audioRef,
  handleAudioTimeUpdate,
  handleAudioLoadedMetadata,
  disabled,
  t,
}: RecordingItemProps) {
  let editIconProperties;
  let deleteIconProperties;

  // Disable edit/delete recording feature for sent or registered song
  if (disabled) {
    editIconProperties = {
      sx: { fontSize: '2rem', color: 'black', opacity: '38%' },
    };
    deleteIconProperties = {
      sx: { fontSize: '2rem', color: 'black', opacity: '38%', ml: 2 },
    };
  } else {
    deleteIconProperties = {
      sx: { fontSize: '2rem', cursor: 'pointer', ml: 2 },
      onClick: () => handleDeleteRecordingClick(parseInt(id, 10)),
    };

    // Disable edit feature for Spotify recordings
    if (spotifyTrackId) {
      editIconProperties = {
        sx: { fontSize: '2rem', color: 'black', opacity: '38%' },
      };
    } else {
      editIconProperties = {
        sx: { fontSize: '2rem', cursor: 'pointer' },
        onClick: () => handleEditRecordingClick(parseInt(id, 10)),
      };
    }
  }

  return (
    <Grid item container alignItems="center" spacing={2}>
      <Grid item>
        <Grid item xs={12} container justifyContent="center">
          {spotifyPreviewUrl &&
            (playingRecordingId === id ? (
              <PauseCircleOutlineIcon
                sx={{ fontSize: '4rem', cursor: 'pointer' }}
                onClick={() => handlePlayPauseClick(id)}
              />
            ) : (
              <PlayCircleOutlineIcon
                sx={{ fontSize: '4rem', cursor: 'pointer' }}
                onClick={() => handlePlayPauseClick(id)}
              />
            ))}
          {!spotifyPreviewUrl && <NotInterested sx={{ fontSize: '4rem' }} />}
        </Grid>
      </Grid>
      <Grid item xs>
        <Grid item xs>
          <Typography variant="subtitle1">{name}</Typography>
          <Typography variant="body1" sx={{ mt: -2 }}>
            {artists.map((artist) => (
              <span key={artist.name}>{artist.name}</span>
            ))}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Box
            onClick={(event) => handleSeek(id, event)}
            sx={{
              backgroundColor: '#D1D5DB',
              height: '10px',
              width: '100%',
              cursor: 'pointer',
            }}
          >
            <Box
              sx={{
                backgroundColor: 'black',
                height: '100%',
                width: `${(currentTime / duration) * 100 || 0}%`,
              }}
            />
          </Box>
        </Grid>
        <Grid item xs={12} textAlign="right">
          <Typography variant="caption">
            {formatTime(currentTime || 0)} / {formatTime(duration || 0)}
          </Typography>
        </Grid>
        <audio
          ref={audioRef} // Directly use the audioRef prop
          src={spotifyPreviewUrl}
          onTimeUpdate={() => handleAudioTimeUpdate(id)}
          onLoadedMetadata={() => handleAudioLoadedMetadata(id)}
          onEnded={() => {
            // Reset the currentTime and pause the playback
            const audioElement = (audioRef as React.RefObject<HTMLAudioElement>)
              ?.current;
            if (audioElement) {
              audioElement.currentTime = 0; // Reset progress to 0
              audioElement.pause(); // Pause the audio
            }
            handlePlayPauseClick(id); // Ensure state reflects the paused status
          }}
          style={{ display: 'none' }} // Hide the default audio controls
        />
      </Grid>
      <Grid item sx={{ mt: 3 }}>
        <Tooltip
          title={
            disabled
              ? t('form.section.recordings.edit-tooltip')
              : t('form.section.recordings.edit-spotify-tooltip')
          }
          arrow
          disableHoverListener={!disabled && spotifyTrackId === ''}
        >
          <EditIcon {...editIconProperties} />
        </Tooltip>
        <Tooltip
          title={t('form.section.recordings.delete-tooltip')}
          arrow
          disableHoverListener={!disabled}
        >
          <DeleteOutlineIcon {...deleteIconProperties} />
        </Tooltip>
      </Grid>
    </Grid>
  );
}
