import {
  Box,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import SectionTitle from '~/base/components/SectionTitle/SectionTitle';
import { Maybe, SongOwnershipType } from '~/types/generated/graphql';
import { Translator } from '~/types/Translator';

interface SongwritersTableProps extends Translator {
  ownerships: Maybe<Maybe<SongOwnershipType>[]> | undefined;
}

function SongwritersTable({ t, ownerships }: SongwritersTableProps) {
  const totalPercent = ownerships?.reduce((acc, ownership) => {
    return acc + (Number(ownership?.percent) || 0);
  }, 0);
  return (
    <Grid container gap={2}>
      <Box sx={{ width: '100%' }}>
        <SectionTitle>{t('form.titles.songwriters')}</SectionTitle>
      </Box>
      <Grid item xs={12}>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <Typography sx={{ fontWeight: 900 }}>
                    {t('form.labels.writer')}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography sx={{ fontWeight: 900 }} align="right">
                    {t('form.labels.ownership')}
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {ownerships?.map((ownership) => (
                <TableRow
                  key={ownership?.songwriter?.id}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {`${ownership?.songwriter?.firstName} 
                    ${ownership?.songwriter?.middleName || ''} 
                    ${ownership?.songwriter?.lastName}`}
                  </TableCell>
                  <TableCell align="right">{`${ownership?.percent}${ownership?.percent ? '%' : ''}`}</TableCell>
                </TableRow>
              ))}
              <TableRow>
                <TableCell>&nbsp;</TableCell>
                <TableCell align="right">
                  <Box sx={{ fontWeight: 900, fontSize: 18 }}>
                    {totalPercent}%
                  </Box>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
}

export default SongwritersTable;
