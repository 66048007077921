import { ApolloError, useReactiveVar } from '@apollo/client';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import FormBanner from '~/base/components/FormBanner';
import { FormBannerType } from '~/base/components/FormBanner/FormBanner';
import { currentSongtrustUserPersonaVar } from '~/cache';
import {
  LoggedInSongtrustUserPersonaQuery,
  RequestOptOutMfaMutation,
  useRequestOptOutMfaMutation,
} from '~/types/generated/graphql';
import { Translator } from '~/types/Translator';

function MfaSection({ t }: Translator): JSX.Element {
  // State and hooks
  const [optOutMfaModalOpen, setOptOutMfaModalOpen] = useState<boolean>(false);
  const [processError, setProcessError] = useState<string>('');
  const [wasSaved, setWasSaved] = useState<boolean>(false);
  const loggedInUser = useReactiveVar(currentSongtrustUserPersonaVar);
  const mfaEnabled = loggedInUser?.loggedInSongtrustUser?.mfaOptIn;
  const navigate = useNavigate();

  // Mutation and handlers
  const [requestOptOutMfaMutation, { loading }] = useRequestOptOutMfaMutation();

  const handleSubmitError = (inError: ApolloError | undefined) => {
    if (inError) {
      setProcessError(inError.message);
    }
  };

  const handleSubmitComplete = (data: RequestOptOutMfaMutation) => {
    if (data?.optOutMfa?.success === false) {
      if (data?.optOutMfa?.errors?.length !== 0) {
        let errorString = '';
        data?.optOutMfa?.errors?.forEach((error) => {
          errorString += `\n${error?.error}`;
        });
        setProcessError(errorString);
      } else {
        setProcessError('Something went wrong!');
      }
    } else {
      setWasSaved(true);
      const updatedUser = {
        ...loggedInUser,
        loggedInSongtrustUser: {
          ...loggedInUser?.loggedInSongtrustUser,
          mfaOptIn: false,
          mfaPhoneNumber: null,
        },
      };
      currentSongtrustUserPersonaVar(
        updatedUser as LoggedInSongtrustUserPersonaQuery,
      );
    }
    setTimeout(() => setOptOutMfaModalOpen(false), 5000);
  };

  const handleToggleMfa = () => {
    if (!mfaEnabled) {
      navigate('/account/security/opt-in-mfa');
      window.scrollTo({ top: 0 });
    } else {
      setOptOutMfaModalOpen(true);
    }
  };

  return (
    <Grid container item xs={12} data-testid="mfa-section" direction="column">
      {optOutMfaModalOpen && (
        <Dialog
          data-testid="opt-out-mfa-modal"
          maxWidth="sm"
          open={optOutMfaModalOpen}
          onClose={() => setOptOutMfaModalOpen(false)}
        >
          <DialogTitle data-testid="opt-out-mfa-modal-title">
            <Typography variant="h3">
              {t('sections.mfa-opt-out-modal.title')}
            </Typography>
          </DialogTitle>
          {/* Success/Error banner */}
          {(processError || wasSaved) && (
            <Grid item xs={12}>
              {!wasSaved && (
                <FormBanner text={processError} type={FormBannerType.ERROR} />
              )}
              {wasSaved && (
                <FormBanner
                  text={t('sections.mfa-opt-out-modal.success')}
                  type={FormBannerType.SUCCESS}
                />
              )}
            </Grid>
          )}
          <DialogContent>
            <DialogContentText>
              {t('sections.mfa-opt-out-modal.copy1')}
            </DialogContentText>
            <DialogContentText sx={{ mt: 3 }}>
              {t('sections.mfa-opt-out-modal.copy2')}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              variant="outlined"
              onClick={() => setOptOutMfaModalOpen(false)}
            >
              {t('sections.mfa-opt-out-modal.cancel')}
            </Button>
            <Button
              disabled={loading || wasSaved}
              data-testid="opt-out-mfa-button"
              variant="contained"
              onClick={() => {
                setProcessError('');
                requestOptOutMfaMutation({
                  onCompleted: handleSubmitComplete,
                  onError: handleSubmitError,
                });
              }}
            >
              {t('sections.mfa-opt-out-modal.disable')}
            </Button>
          </DialogActions>
        </Dialog>
      )}
      {mfaEnabled ? (
        // MFA enabled content
        <>
          {/* Body */}
          <Typography variant="body1">
            {t('sections.mfa.enabled-copy1')}
          </Typography>
          <br />
          <Typography variant="body1" data-testid="enabled-copy" sx={{ mb: 1 }}>
            {t('sections.mfa.enabled-copy2')}
          </Typography>
          {/* Phone number */}
          <Typography variant="h5" sx={{ pb: 0 }}>
            {t('sections.mfa.phone-number')}
          </Typography>
          <Typography variant="h6" sx={{ pt: 0 }}>
            {loggedInUser?.loggedInSongtrustUser?.mfaPhoneNumber}
          </Typography>
          {/* Delivery type */}
          <Typography variant="h5" sx={{ pb: 0 }}>
            {t('sections.mfa.delivery-type1')}
          </Typography>
          <Typography variant="h6" sx={{ pt: 0 }}>
            {t('sections.mfa.delivery-type2')}
          </Typography>
        </>
      ) : (
        // MFA disabled content
        <Typography variant="body1" data-testid="disabled-copy">
          {t('sections.mfa.disabled-copy')}
        </Typography>
      )}

      {/* MFA toggle button */}
      <Grid container justifyContent="flex-end">
        <Button
          data-testid="mfa-button"
          variant="contained"
          color="secondary"
          sx={{ mt: 2 }}
          onClick={handleToggleMfa}
        >
          {mfaEnabled
            ? t('sections.mfa.enabled-button')
            : t('sections.mfa.disabled-button')}
        </Button>
      </Grid>
    </Grid>
  );
}

export default MfaSection;
