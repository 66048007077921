import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  Grid,
  IconButton,
  MenuItem,
  MenuList,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import FormAutoComplete from '~/base/components/FormAutoComplete/FormAutoComplete';
import FormInput from '~/base/components/FormInput';
import { useArtistsQuery } from '~/types/generated/graphql';
import { Translator } from '~/types/Translator';

export type ArtistProps = {
  id: string | null;
  name: string;
};

interface AddArtistButtonProps extends Translator {
  artistName: string;
  addNewArtist: (id: string | null, name: string) => void;
}

interface AddManualRecordingProps extends Translator {
  handleArtistSelection: (arg: boolean) => void;
}

function AddArtistButton({
  t,
  artistName,
  addNewArtist,
}: AddArtistButtonProps) {
  const handleAddArtist = (e: React.SyntheticEvent) => {
    e.stopPropagation();
    if (artistName) {
      addNewArtist(null, artistName as string);
    }
  };

  return (
    <MenuList sx={{ width: '100%', padding: 0 }} onClick={handleAddArtist}>
      <MenuItem id="add-artist-button">
        <Typography
          display="flex"
          alignItems="center"
          sx={{
            pl: '0.625rem',
          }}
          variant="button"
        >
          <AddIcon
            sx={{ height: '1.125rem', fontSize: '2rem', pr: '0.5rem' }}
          />
          {t(
            `page.add-recording.tabs.manual.artists.fields.search-artists.add-artist`,
          )}
        </Typography>
      </MenuItem>
    </MenuList>
  );
}

export default function AddManualRecording({
  t,
  handleArtistSelection,
}: AddManualRecordingProps) {
  const [artistName, setArtistName] = useState('');
  const [artistSearchList, setArtistSearchList] = useState<
    ArtistProps[] | undefined
  >(undefined);
  const [selectedArtists, setSelectedArtists] = useState<ArtistProps[]>([]);

  // Fetch artists using GraphQL query
  useArtistsQuery({
    fetchPolicy: 'cache-and-network',
    onCompleted: (res) => {
      // Filter out artists with duplicate names
      const artistResults = res.artists;
      if (artistResults) {
        const uniqueArtistNames = artistResults.reduce(
          (accumulator: { id: string; name: string }[], artist) => {
            if (
              artist?.name &&
              !accumulator.some(
                (uniqueArtist) => uniqueArtist.name === artist?.name,
              )
            ) {
              accumulator.push({ id: artist.id, name: artist.name });
            }
            return accumulator;
          },
          [],
        );
        setArtistSearchList(uniqueArtistNames);
      }
    },
  });

  const { control } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    name: 'artists',
    control,
  });

  // Create new TextField for selected artist
  const handleSelectArtist = (value: string | null, label: string) => {
    if (label) {
      let artistId = value;

      // Find artist from search list if user tries to add existing artist
      if (!artistId) {
        const artistInSearchList = artistSearchList?.find(
          (artist) => artist.name === label,
        );
        artistId = artistInSearchList ? artistInSearchList.id : null;
      }

      setArtistSearchList((prev) =>
        prev?.filter((artist) => artist?.name !== label),
      );

      append({ id: artistId, name: label });
      setSelectedArtists((prev) => [
        ...(prev ?? []),
        { id: artistId, name: label },
      ]);
    }
  };

  // Form validation for artist input requirement
  useEffect(() => {
    handleArtistSelection(fields.length > 0);
  }, [fields]);

  return (
    <>
      {fields.map((field, i) => {
        return (
          <Grid key={field.id} item xs={12}>
            <Box
              sx={{
                width: '100%',
                justifyContent: 'center',
                justifyItems: 'center',
                display: 'flex',
                mb: 2,
              }}
            >
              <FormInput
                key={field.id}
                id={`artists-${i}-name`}
                name={`artists.${i}.name`}
                label={t(
                  `page.add-recording.tabs.manual.artists.fields.search-artists.label`,
                )}
                sx={{ flexGrow: '1', width: '90%' }}
                inputProps={{ 'data-testid': `artist-${i}`, readOnly: true }}
                inputFullWidth
                clearErrorOnFocus
              />
              <Box sx={{ pt: 3 }}>
                <IconButton
                  data-testid={`delete-artist-${i}`}
                  onClick={() => {
                    const typedField = field as ArtistProps;

                    const removedArtist = selectedArtists.find(
                      (artist) => artist.name === typedField.name,
                    );
                    // Add removed, existing artist back to search dropdown
                    if (removedArtist && removedArtist.id) {
                      setArtistSearchList([
                        { id: removedArtist.id, name: removedArtist.name },
                        ...(artistSearchList ?? []),
                      ]);
                    }

                    setSelectedArtists(
                      selectedArtists.filter(
                        (artist) => artist.name !== typedField.name,
                      ),
                    );
                    remove(i);
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </Box>
            </Box>
          </Grid>
        );
      })}
      <FormAutoComplete
        id="artists-search-bar"
        testid="artist-search-bar"
        name="artists-search-bar"
        label={t(
          `page.add-recording.tabs.manual.artists.fields.search-artists.label`,
        )}
        placeholder={t(
          `page.add-recording.tabs.manual.artists.fields.search-artists.placeholder`,
        )}
        options={
          artistSearchList
            ? artistSearchList.map((artist) => ({
                choiceId: artist.id as string,
                choiceLabel: artist.name as string,
              }))
            : []
        }
        onOptionSelected={handleSelectArtist}
        sx={{ width: '95%', mb: 4 }}
        freeSolo
        displayRequired
        required={{
          value: fields.length < 1,
          message: t(
            'page.add-recording.tabs.manual.artists.fields.search-artists.required',
          ),
        }}
        autocompleteSX={{ width: '100%' }}
        clearInput
        defaultOption={{
          choiceId: '-1',
          choiceLabel: t(
            `page.add-recording.tabs.manual.artists.fields.search-artists.add-artist`,
          ),
        }}
        DefaultComponent={
          <AddArtistButton
            t={t}
            artistName={artistName}
            addNewArtist={handleSelectArtist}
          />
        }
        onInputChange={(event: React.SyntheticEvent, value: string) => {
          setArtistName(value);
        }}
      />
    </>
  );
}
