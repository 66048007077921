import {
  FormControl,
  FormHelperText,
  Grid,
  InputBaseComponentProps,
  InputLabel,
  TextField,
} from '@mui/material';
import { Theme } from '@mui/material/styles';
import { SxProps } from '@mui/system';
import MuiPhoneNumber from 'material-ui-phone-number';
import React from 'react';
import {
  Controller,
  FieldError,
  Validate,
  ValidationRule,
  useFormContext,
} from 'react-hook-form';
import FormTooltip from '~/base/components/FormTooltip';
import EndAdornment from './EndAdornment';

// This block is a fix for an issue with the exports from MuiPhoneNumber that mess up testing.
/* eslint-disable */
// @ts-ignore
const MaterialPhoneNumber = MuiPhoneNumber.default
  ? // @ts-ignore
    MuiPhoneNumber.default
  : MuiPhoneNumber;
/* eslint-enable */

interface FormInputProps {
  id: string;
  label?: string;
  name: string;
  tooltip?: React.ReactNode;
  tooltipLabel?: string;
  tooltipType?: 'dialog' | 'default';
  sx?: SxProps<Theme>;
  displayRequired?: boolean;
  required?: string | ValidationRule<boolean> | undefined;
  pattern?: {
    value: RegExp;
    message: string;
  };
  textError?: FieldError;
  validate?:
    | Validate<unknown, unknown>
    | Record<string, Validate<unknown, unknown>>
    | undefined;
  type?: string;
  children?: React.ReactNode;
  placeholder?: string;
  isPhoneNumber?: boolean;
  autocomplete?: boolean;
  disabled?: boolean;
  inputProps?: InputBaseComponentProps | undefined;
  inputFullWidth?: boolean;
  endAdornment?: React.ReactNode;
  multiline?: boolean;
  rows?: number;
  clearErrorOnFocus?: boolean;
}

const inputDefaultStyle = {
  borderRadius: '.5rem',
  height: '3.125rem',
  marginTop: '1rem',

  '& > fieldset': {
    borderColor: '#7E7E7E',
  },
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: '#031C9B',
  },
};

const inputDefaultStyleMultiline = {
  borderRadius: '.5rem',
  minHeight: '10rem',
  maxHeight: '10rem',
  marginTop: '1rem',

  '& > fieldset': {
    borderColor: '#7E7E7E',
  },
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: '#031C9B',
  },
  textarea: {
    minHeight: '10rem',
    maxHeight: '10rem',
    overflowY: 'auto !important',
  },
};

const phoneDefaultStyle = {
  paddingTop: 0,
  borderRadius: '.5rem',
  height: '3.125rem',
  marginTop: '1rem',
  '.MuiInputAdornment-root': {
    marginRight: '1.5rem',
  },
  '.MuiInputAdornment-root svg': {
    height: '65%',
  },
  '.MuiInputAdornment-root button::after': {
    color: '#000',
    content: '"\u25BE"',
    left: '2rem',
    position: 'absolute',
  },
  '.MuiButtonBase-root': {
    maxWidth: '2rem',
  },
  '.MuiOutlinedInput-root': {
    borderRadius: '8px',
    height: '3.125rem',
    fieldset: {
      borderColor: '#7E7E7E',
    },
    '&.Mui-focused': {
      borderColor: '#031C9B',
      borderWidth: '2px',
      fieldset: {
        borderColor: '#031C9B',
        borderWidth: '2px',
      },
    },
  },
};

/**
 * FormInput component renders a form input field with various customization options.
 *
 * @param {FormInputProps} props - The properties for the form input.
 * @returns {JSX.Element} The rendered form input component.
 */
function FormInput({
  id,
  name,
  label,
  tooltip,
  tooltipLabel,
  tooltipType = 'default',
  sx = {},
  displayRequired = false,
  required = false,
  pattern,
  textError,
  validate,
  type = 'text',
  children = [],
  placeholder = '',
  isPhoneNumber = false,
  autocomplete = true,
  disabled = false,
  inputProps,
  inputFullWidth,
  endAdornment,
  multiline = false,
  rows,
  clearErrorOnFocus = false,
}: FormInputProps): JSX.Element {
  const {
    setValue,
    control,
    register,
    clearErrors,
    formState: { errors },
  } = useFormContext();

  const {
    name: fieldName,
    onChange,
    onBlur,
    ref,
  } = register(name, {
    required,
    pattern,
    validate,
  });

  let error = id in errors ? errors[id] : '';
  if (!error) error = name in errors ? errors[name] : '';

  // Input stylings for disabled fields
  let disabledStyle = {};
  if (disabled) {
    disabledStyle = {
      backgroundColor: 'rgba(0, 0, 0, 0.07)',
      '& .MuiInputBase-input': {
        '-webkit-text-fill-color': 'rgba(0, 0, 0, 0.38)',
        '-webkit-background-clip': 'text', // ensures styling is clipped to text
      },
    };
  }

  return (
    <FormControl sx={sx} variant="standard">
      {children}

      {label && (
        <InputLabel
          htmlFor={id}
          shrink={false}
          sx={{
            width: '100%',
            zIndex: 1,
            color: disabled ? 'rgba(0, 0, 0, 0.38)' : '',
          }}
        >
          <Grid container justifyContent="space-between">
            <Grid item>
              {label}{' '}
              {displayRequired ? (
                <span style={{ color: '#ba3310' }}>
                  &#65290;
                  <span style={{ display: 'none' }}>required</span>
                </span>
              ) : (
                ''
              )}
            </Grid>
            <Grid item>
              {tooltip && (
                <FormTooltip
                  label={tooltipLabel}
                  tooltip={tooltip}
                  type={tooltipType}
                />
              )}
            </Grid>
          </Grid>
        </InputLabel>
      )}

      {isPhoneNumber && (
        <Controller
          render={({ field: { value } }) => (
            <MaterialPhoneNumber
              defaultCountry="us"
              disableAreaCodes
              id={id}
              data-testid={id}
              aria-describedby={label}
              disabled={disabled}
              error={!!error}
              ref={ref}
              onBlur={onBlur}
              onChange={(v: string) => {
                setValue(fieldName, v);
              }}
              value={value}
              name={fieldName}
              placeholder={placeholder}
              sx={phoneDefaultStyle}
              variant="outlined"
              type="text"
              fullWidth
            />
          )}
          name={fieldName}
          control={control}
        />
      )}

      {!isPhoneNumber && (
        <TextField
          id={id}
          data-testid={id}
          aria-describedby={label}
          disabled={disabled}
          error={!!error || !!textError}
          onChange={onChange}
          ref={ref}
          onBlur={onBlur}
          type={type}
          name={fieldName}
          multiline={multiline}
          rows={rows}
          placeholder={placeholder}
          autoComplete={autocomplete ? 'on' : 'off'}
          onFocus={() => {
            if (clearErrorOnFocus) clearErrors([fieldName, id]);
          }}
          InputProps={{
            sx: multiline
              ? { ...inputDefaultStyleMultiline, ...disabledStyle }
              : { ...inputDefaultStyle, ...disabledStyle },
            endAdornment: (
              <EndAdornment
                {...{ error: !!error || !!textError, id, endAdornment }}
              />
            ),
            inputProps,
          }}
          fullWidth={inputFullWidth}
        />
      )}

      {error && typeof error !== 'string' && error.message && (
        <FormHelperText
          data-testid={`${id}-error-message`}
          sx={{ color: '#F33126' }}
        >
          {error?.message as unknown as string}
        </FormHelperText>
      )}
    </FormControl>
  );
}

export default FormInput;
