import { Box } from '@mui/material';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import { useParams } from 'react-router-dom';
import PageTitle from '~/base/components/PageTitle/PageTitle';
import usePageTitle from '~/hooks/usePageTitle';
import { T } from '~/types/Translator';
import { useSongByIdQuery } from '~/types/generated/graphql';
import AddManualRecording from '../AddManualRecording/AddManualRecording';

export default function EditRecordingsPage({ t }: { t: T }) {
  const params = useParams();
  usePageTitle(t('page.edit-recording.title'));

  const { data: songData, loading } = useSongByIdQuery({
    variables: { id: Number(params.id || '') },
    onCompleted: (res) => {
      if (res?.song?.title) {
        document.title = document.title.replace(
          t('page.edit-recording.title'),
          `${t('page.edit-recording.title')} | ${res?.song?.title}`,
        );
      }
    },
  });

  return (
    <Container>
      <PageTitle
        currentPage={t('page.edit-recording.title')}
        breadcrumbLinks={[
          {
            id: 'songs-back-link',
            label: t('page.titles.breadcrumb'),
            href: '/songs',
          },
          {
            id: 'song-edit-back-link',
            label: songData?.song?.title || '',
            href: `/songs/${songData?.song?.id}/edit`,
          },
        ]}
        pageTitle={{
          title: loading ? '...' : songData?.song?.title,
          id: 'recording-song-page-title',
        }}
      />
      <Grid container item xs={12} md={12}>
        <Box width="100%">
          <AddManualRecording t={t} recordingIsrcList={[]} />
        </Box>
      </Grid>
    </Container>
  );
}
