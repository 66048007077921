import { Container, Grid } from '@mui/material';
import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import usePageTitle from '~/hooks/usePageTitle';
import { ChannelEnum } from '~/types/generated/graphql';
import { Translator } from '~/types/Translator';
import MfaFormStepOne from './MfaFormStepOne';
import MfaFormStepTwo from './MfaFormStepTwo';

interface MfaFormValues {
  'mfa-phone-number': string | null | undefined;
  'mfa-code': string;
  'mfa-channel': ChannelEnum;
}

function MfaPage({ t }: Translator): JSX.Element {
  usePageTitle(t('sections.security.title'));
  const location = useLocation();
  const navigate = useNavigate();

  // If this component is being rendered as part of the opt in process
  // start at step 1 allowing user to enter their desired phone number.
  // Otherwise initialize form with user's sanitizied phone number provided in state from useNavigate hook at login.
  const userOptingIn = location.pathname.includes('opt-in-mfa');
  const [mfaStep, setMfaStep] = useState<number>(userOptingIn ? 1 : 2);
  const mfaForm = useForm<MfaFormValues>({
    defaultValues: {
      'mfa-phone-number': userOptingIn
        ? ''
        : location?.state?.sanitizedPhoneNumber,
      'mfa-code': '',
      'mfa-channel': ChannelEnum.Sms,
    },
  });

  const handleCancel = () => {
    if (userOptingIn) {
      navigate('/account/security');
    } else {
      navigate('/login');
    }
  };

  return (
    <Container>
      <Grid container data-testid="mfa-container">
        <FormProvider {...mfaForm}>
          {mfaStep === 1 && (
            <MfaFormStepOne
              t={t}
              setMfaStep={setMfaStep}
              handleCancel={handleCancel}
            />
          )}
          {mfaStep === 2 && (
            <MfaFormStepTwo t={t} handleCancel={handleCancel} />
          )}
        </FormProvider>
      </Grid>
    </Container>
  );
}

export default MfaPage;
