import { Grid, Typography } from '@mui/material';
import { HashLink } from 'react-router-hash-link';

function StyledSeparator() {
  return (
    <Grid item xs={12}>
      <hr style={{ margin: 0 }} />
    </Grid>
  );
}

interface StyledGridContent {
  children: JSX.Element | JSX.Element[];
}

function StyledGridContent({ children }: StyledGridContent) {
  return (
    <Typography
      variant="body1"
      component="div"
      sx={{
        'p:first-of-type': {
          marginTop: 0,
        },
        'p:last-child': {
          marginBottom: 0,
        },
      }}
    >
      {children}
    </Typography>
  );
}

function TermsPageContent() {
  const emphasisStyle = {
    em: { color: '#D5164B', fontStyle: 'normal', textDecoration: 'underline' },
    span: {
      fontStyle: 'italic',
    },
  };
  const hashLinkStyle = { color: '#D5164B' };

  return (
    <Grid
      container
      spacing={3}
      data-testid="terms-page-content"
      sx={emphasisStyle}
    >
      <Grid item xs={12} md={12}>
        <Typography
          variant="body1"
          sx={{
            paddingTop: { xs: '1rem' },
          }}
        >
          The following document outlines the terms of service for the Songtrust
          Site and Service (each as defined below). You may only create an
          account by reading and accepting these terms. You can also review our{' '}
          <HashLink
            target="_blank"
            to="https://www.songtrust.com/privacy-policy"
            style={hashLinkStyle}
          >
            Privacy Policy
          </HashLink>
          , which outlines our practices towards handling any personal
          information that you may provide to us. The column on the right
          provides short explanations to assist in your review of the agreement.
          You understand and acknowledge that such explanations alone are not
          binding, and by registering for the Services and/or creating an
          Account you understand that you are agreeing to the entirety of the
          agreement.
        </Typography>
        <Typography
          variant="h4"
          component="h4"
          sx={{
            paddingTop: { xs: '2rem' },
          }}
        >
          As of JANUARY 1, 2025
        </Typography>
      </Grid>
      <StyledSeparator />
      <Grid item xs={12}>
        <StyledGridContent>
          <p>
            You and ST Music LLC (“Songtrust” or “us”) agree that your access to
            and use of the{' '}
            <HashLink to="#site" style={hashLinkStyle}>
              Site
            </HashLink>{' '}
            and{' '}
            <HashLink to="#services" style={hashLinkStyle}>
              Services
            </HashLink>{' '}
            (as defined below) is subject to your agreement to the terms and
            conditions listed below, which, together with the general terms of
            service for the Songtrust website (
            <HashLink
              to="https://songtrust.com/"
              target="_blank"
              style={hashLinkStyle}
            >
              located here
            </HashLink>
            ) and our privacy policy (
            <HashLink
              target="_blank"
              to="https://www.songtrust.com/privacy-policy"
              style={hashLinkStyle}
            >
              located here
            </HashLink>
            ) (which outlines our practices towards handling any personal
            information you may provide to us) will become a binding agreement
            between you and Songtrust (the &quot;agreement&quot;). Songtrust is
            willing to allow you access to the Site and is willing to perform
            the requested services (the &quot;Services&quot;) only upon the
            condition that you accept all of the terms of this agreement. Please
            read the agreement carefully, including the Definitions section at
            the end. After reading the agreement, if you agree and wish to
            create an account with us to use the Services (each, an
            &quot;Account&quot;), please indicate your decision by clicking on
            &quot;Create your Songtrust Account&quot;. If you do not agree to
            all the terms and conditions of this agreement, do not register to
            use the Services.
          </p>
        </StyledGridContent>
      </Grid>
      <StyledSeparator />
      <Grid item xs={12}>
        <StyledGridContent>
          <p>
            PLEASE NOTE THAT THIS AGREEMENT CONTAINS A BINDING ARBITRATION
            CLAUSE AND CLASS ACTION WAIVER, WHICH EACH IMPACT YOUR RIGHTS
            REGARDING HOW TO RESOLVE DISPUTES. BY USING THE SERVICE YOU AGREE
            THAT DISPUTES BETWEEN YOU AND US WILL BE RESOLVED BY BINDING,
            INDIVIDUAL ARBITRATION AND YOU WAIVE YOUR RIGHT TO PARTICIPATE IN A
            CLASS ACTION LAWSUIT OR CLASS-WIDE ARBITRATION. CERTAIN EXCEPTIONS
            AND HOW YOU CAN OPT OUT OF ARBITRATION ARE EXPLAINED BELOW.
          </p>
        </StyledGridContent>
      </Grid>
      <StyledSeparator />
      <Grid item xs={12}>
        <StyledGridContent>
          <p>
            If you are using the Site, entering into this agreement for the
            Services, registering and creating an Account and/or providing
            information to us on behalf of one or more other writers, a group,
            or a music publisher/administrator or other entity, then you
            represent and warrant to us that you are duly authorized to do so on
            behalf of the applicable people or entities and to bind them to this
            agreement. As used herein, the term “you” includes all such people
            and entities.
          </p>
        </StyledGridContent>
      </Grid>
      <StyledSeparator />
      <Grid item xs={12}>
        <StyledGridContent>
          <p>
            <span>GRANT OF RIGHTS.</span>
          </p>
          <p>
            During the{' '}
            <HashLink to="#term" style={hashLinkStyle}>
              Term
            </HashLink>{' '}
            and{' '}
            <HashLink to="#exploitation-period" style={hashLinkStyle}>
              Exploitation Period
            </HashLink>
            , (as applicable), you hereby grant to Songtrust, its successors,
            licensees and assigns, the sole and exclusive rights of
            administration, promotion and collection throughout the{' '}
            <HashLink to="#territory" style={hashLinkStyle}>
              Territory
            </HashLink>{' '}
            with respect to one hundred percent (100%) of all of your right,
            title and interest (“Your Interest”) in and to the musical
            compositions you submit to Songtrust via the Site (“Compositions”),
            except for (i) Traditional Sync Licensing, which rights are retained
            by you (see detail below), and (ii) any other Services from which
            you expressly opt-out as permitted via your Account (sub-clauses (i)
            and (ii) are referred to collectively as the “Exclusions”). Except
            as provided in the preceding sentence, the rights you grant to
            Songtrust include, without limitation, the following sole and
            exclusive rights, licenses, privileges and authority throughout the
            Territory with respect to Your Interest in and to all Compositions,
            whether now in existence or whether created during the Term, as
            follows:
          </p>
          <p>
            1. To register Your Interest in the Compositions, if you have not
            already done so, at your request and on your behalf, with the
            relevant performance rights organizations (e.g., ASCAP or BMI) and
            other licensing agencies (e.g., The Harry Fox Agency, Mechanical
            Licensing Collective) in accordance with their then current rules,
            terms and conditions. If you are already affiliated or registered,
            we will require your signature on one or more letters of direction
            to enable us to administer your account with such society or agency
            on your behalf during the Term and Exploitation Period. For the
            avoidance of doubt, we will not be able to start collecting Net Sums
            on your behalf unless and until the{' '}
            <HashLink to="#registration" style={hashLinkStyle}>
              Registration
            </HashLink>{' '}
            is accurately completed, including, without limitation, acceptance
            of the registration of Your Interest in the Compositions by all
            relevant performance rights organizations and other licensing
            agencies.{' '}
          </p>
          <p>
            2. To perform and license others to perform Your Interest in the
            Compositions (including, without limitation, publicly or privately,
            for profit or otherwise, by means of public or private performance,
            radio broadcast, television, Internet, mobile telecom, or by any
            other means or media, whether now known or hereafter conceived or
            developed, including, without limitation, the right to grant
            so-called “direct” performance licenses (including the writer’s
            share of performance).
          </p>
          <p>
            3. At your request or with your permission, to substitute new titles
            for any of the Compositions, and to make any arrangement,
            adaptation, translation, dramatization or transposition of any of
            the Compositions or of the titles, lyrics or music thereof, in whole
            or in part, and in connection with any other musical, literary or
            dramatic material, and to add new lyrics to the music of any
            Composition or new music to the lyrics of any Composition, and to
            prepare derivative works based on the Compositions, regardless of
            any so-called &quot;moral rights&quot;.
          </p>
          <p>
            4. To make or cause to be made, and to license others to make
            phonograph records, master recordings, digital downloads (including
            so-called limited, conditional or “tethered” downloads), streams (on
            an interactive or non-interactive basis), podcasts, ringtones,
            transcriptions, soundtracks, pressings and any other mechanical,
            physical, digital or other reproductions of the Compositions, in
            whole or in part, including without limitation, the right to grant
            licenses to third parties authorizing so-called &quot;sampling&quot;
            and/or interpolation of the Compositions, and to use, manufacture,
            advertise, license, sell, or otherwise exploit such reproductions
            for any and all purposes and any and all media, including, without
            limitation, private and public performances, radio broadcast,
            television, sound motion pictures, wired radio, phonograph records,
            streaming services, karaoke services, jukebox services, background
            music services and any and all other means and devices, whether now
            known or hereafter conceived or developed.
          </p>
          <p>
            5. To grant, administer and collect under so-called blanket
            audio-visual licenses, including “micro sync” licenses as that term
            is understood in the U.S. music publishing industry, and blanket
            audio-only licenses (collectively, “Blanket Licenses”). Blanket
            Licenses include, by way of example and not limitation, licenses for
            use of Compositions (or portions thereof, including lyrics or
            metadata) in videos, “art tracks,” on-site advertising and other
            audio-visual content on social media platforms, within
            user-generated videos, slideshows, presentations and similar
            multimedia projects, in software apps or digital environments and/or
            experiences, and on video sites and services such as YouTube,
            TikTok, Snapchat, Facebook, Instagram, as well as digital fitness
            programs and Web3 platforms. Songtrust’s rights with respect to
            Blanket Licenses also include, without limitation, the rights to
            synchronize the Compositions in timed relation with audiovisual
            content available on such services and to create derivative works of
            the Compositions through features made available by such platforms.
            Unless you affirmatively opt-out of our Services with respect to
            YouTube through your Account (which such opt-out may be available on
            a songwriter-by-songwriter basis), Songtrust’s rights include the
            right to license your Compositions on YouTube and to identify,
            “claim” and “monetize” videos on the platform containing your
            Compositions by allowing the display of advertising, although you
            will be able to review those videos by logging into your Account and
            it will be your responsibility to review those videos and confirm
            that they do, in fact, contain your Compositions. If you are a
            YouTube “partner” and provide us with accurate details identifying
            your YouTube channel, then subject to the rest of these terms, we
            will “allowlist” and not monetize your channel.
          </p>
          <p>
            6. To print, publish, sell and multiply, and to authorize others to
            print, publish, sell and multiply, copies of the Compositions, in
            all forms, including, without limitation, sheet music,
            orchestrations, arrangements and other editions of the Compositions,
            separately or together with other musical Compositions, including,
            without limitation, in song folios, compilations, song books, mixed
            folios, personality folios and lyric magazines, with or without
            music.
          </p>
          <p>
            7. To reproduce, modify, display, encode, integrate or otherwise
            exploit the credit data and metadata associated with the
            Compositions.
          </p>
          <p>
            8. To sublicense any or all of the rights granted herein to any
            persons or entities, subject to any approvals or restrictions
            contained herein.
          </p>
          <p>
            9. Except for the Exclusions, to exercise and exploit during the
            Term and Exploitation Period, exclusively, any and all other rights
            now or hereafter existing with regard to Your Interest in any and
            all Compositions under and by virtue of any common law or statutory
            laws or provisions, including without limitation, copyright laws,
            including so-called grand rights and small performance rights.
          </p>
          <p>
            10. The right, but not the obligation, to prosecute, defend and
            settle all claims and actions with respect to the Compositions, and
            generally to do all things necessary concerning the same and the
            copyrights or other rights with respect to the Compositions;
            provided, however, Songtrust shall not settle claims without your
            consent (other than as provided in paragraph 12 of this section). In
            the event of a recovery by Songtrust or you of any monies as a
            result of a judgment or settlement, such monies shall be divided
            between you and Songtrust as same is provided in the “PAYMENTS”
            section below, after first deducting the out-of-pocket expenses
            incurred by Songtrust of obtaining said monies, including reasonable
            legal fees and expenses. You shall have the right to provide counsel
            for yourself, to assist in or assume the prosecution or defense of
            any such matter, but at your own expense.
          </p>
          <p>
            11. The right, but not the obligation, to enter into blanket, group
            or class suits and settlements (e.g., NMPA settlements) for the
            recovery of royalties presented and endorsed by advocacy groups on
            your behalf and in connection with the Compositions, without your
            prior consent. Monetary recoveries from such settlements shall be
            allocated by Songtrust in its sole, reasonable business discretion,
            after first deducting Songtrust’s related industry dues and any
            out-of-pocket expenses incurred by Songtrust of obtaining said
            monies, including reasonable legal fees and expenses.
          </p>
          <p>
            12. To administer and collect all monies derived from the
            exploitation of the Compositions during the Term and Exploitation
            Period throughout the Territory (except for the Exclusions).
          </p>
          <p>
            13. At your request or with your permission, to use the names (real
            and professional), approved biographical information and approved
            likenesses of the writers and publishers/administrators of the
            Compositions (including but not limited to you) solely in connection
            with the marketing and/or promotion of the Site, our Services and
            the Compositions delivered hereunder.
          </p>
          <p>
            You acknowledge that the term of individual licenses may extend
            beyond the Term and/or Territory hereof and you authorize Songtrust
            to enter into such licenses on your behalf.
          </p>
        </StyledGridContent>
      </Grid>
      <StyledSeparator />
      <Grid item xs={12}>
        <StyledGridContent>
          <p>
            <span>TRADITIONAL SYNC LICENSES</span>
          </p>
          <p>
            You retain the exclusive right to negotiate and grant
            synchronization licenses on whatever terms you establish for the use
            of your Compositions via “traditional” means, as that term is
            understood in the U.S. music publishing industry, such as one-off
            licenses for use in films, television productions, commercials, and
            video games, excluding uses under Blanket Licenses (collectively,
            “Traditional Sync Licenses”). Any such Traditional Sync Licenses
            will be between you and your licensees. You also retain the right to
            collect the license fees from such Traditional Sync Licenses. Any
            Traditional Sync License requests (“Sync License Request(s)”)
            received by Songtrust on your behalf will be promptly forwarded to
            you via the contact information provided in your Account. Following
            your receipt of a Request, you may request that Songtrust provide
            non-exclusive Traditional Sync License administration services with
            respect to Your Interest in the Composition referenced in such
            Request. Songtrust shall have the right, but not obligation, to
            accept your request for such services and you will engage Songtrust
            for such services on the terms specified below. If you engage
            Songtrust to handle a Request, and Songtrust agrees to handle same
            (in Songtrust’s discretion), such Request will be deemed approved by
            you and Songtrust will negotiate and enter into an agreement on your
            behalf with respect to such Request, and such agreement will provide
            that payment with respect to any license fees will be paid directly
            to Songtrust on your behalf. Songtrust will pay to you Your Share of
            Net Sums received from such license agreements in accordance with
            the “PAYMENTS” section below. Notwithstanding the expiration or
            earlier termination of the Term or Exploitation Period, Songtrust’s
            rights to receive payment in connection with licenses in accordance
            with this paragraph will continue with respect to all requested and
            approved licenses issued by Songtrust prior to such expiration or
            earlier termination of the Term or Exploitation Period.
          </p>
        </StyledGridContent>
      </Grid>
      <StyledSeparator />
      <Grid item xs={12}>
        <StyledGridContent>
          <p>
            <span>APPROVALS</span>
          </p>
          <p>
            Wherever your approval or consent is required pursuant to this
            agreement, the relevant use shall be deemed to have been approved in
            the event you fail to respond to a request within ten (10) business
            days of the date of such request.
          </p>
        </StyledGridContent>
      </Grid>
      <StyledSeparator />
      <Grid item xs={12}>
        <StyledGridContent>
          <p>
            <span>YOUR ACCOUNT</span>
          </p>
          <p>
            1. You will be asked as part of Registration to select a unique user
            name and password for your Account and to provide Songtrust with
            accurate, complete registration information to obtain access to the
            Services, which may include, without limitation, a valid email
            address, name, age, postal address, phone number, photo
            identification, social security number, other tax ID number or other
            payment details. You will also be required to provide a tax form,
            verified by Songtrust and/or its third-party payment processor(s),
            as well as provide an update of same, and any other information
            provided, as reasonably requested by Songtrust. Failure to provide
            any of the foregoing (or any requested updates) may result in a
            delay in payment or cancellation of your Account. You will be solely
            responsible for any and all activity transacted and charges incurred
            through your Account, so please ensure that you keep your username,
            password and other Account information confidential and safe.
          </p>
          <p>
            2. If at any time your registration information changes or if you
            learn or suspect that your password has been used or obtained by a
            person not authorized to use it, please notify Songtrust immediately
            through the following link:{' '}
            <HashLink
              target="_blank"
              to="https://www.songtrust.com/contact-support"
              style={hashLinkStyle}
            >
              https://www.songtrust.com/contact-support
            </HashLink>
            . Any materials (physical or digital) submitted to Songtrust will
            not be returned.
          </p>
          <p>
            You may not assign or transfer your account, rights, obligations, or
            interest under this agreement to anyone else unless otherwise agreed
            in writing by Songtrust. Any assignment, encumbrance or other
            transfer of Your Interest in any Composition(s) will remain subject
            to this agreement during the Term and Exploitation Period. A
            purported assignment, encumbrance or other transfer not in
            accordance with this paragraph will be void and without effect ab
            initio.
          </p>
        </StyledGridContent>
      </Grid>
      <StyledSeparator />
      <Grid item xs={12}>
        <StyledGridContent>
          <p>
            <span>SUBMITTING COMPOSITIONS</span>
          </p>
          <p>
            Following Registration, you may submit as many Compositions as you
            wish for Songtrust to administer pursuant to the Services and this
            agreement. When submitting Compositions, we may require that you
            provide iswcs, isrcs or other identifying codes as well as your
            percentage copyright interest which makes up Your Interest of each
            Composition. At Songtrust’s request, you shall execute and deliver
            to Songtrust any necessary documents regarding the rights granted to
            Songtrust in the Compositions, and if you fail to do so within ten
            (10) business days following Songtrust’s request thereof, Songtrust
            may sign such documents on your behalf or cancel your Account (in
            Songtrust’s discretion).
          </p>
          <p>
            Songtrust reserves the right to decline to administer (or to revoke
            administration of) any of the Compositions or other content you
            provide to us as part of the Services, for any reason, including
            without limitation, if such Compositions or other content is (i)
            deemed objectionable, (ii) violates this agreement in any way or the
            terms and conditions of any of our third party licensees, (iii) a{' '}
            <HashLink to="#non-qualified-work" style={hashLinkStyle}>
              Non-Qualified Work
            </HashLink>
            , (iv) infringing on the rights of a third party or is related to
            Illegitimate Activity, or (v) for any reason in our business
            judgment.
          </p>
        </StyledGridContent>
      </Grid>
      <StyledSeparator />
      <Grid item xs={12}>
        <StyledGridContent>
          <p>
            <span>SONGTRUST FEES</span>
          </p>
          <p>
            In consideration of the Services rendered hereunder, you shall be
            obligated to pay to Songtrust the fees described in the relevant
            Registration (“Registration Fee”). During Registration, you must
            provide Songtrust with credit card information and authorization to
            charge your Registration Fee, any applicable taxes, and other
            charges you may incur in connection with your use of the Site and
            Services directly to your credit card account. For the avoidance of
            doubt, if you or Songtrust terminate this Agreement for any reason,
            you will not be entitled to reimbursement of the Registration Fee.
          </p>
        </StyledGridContent>
      </Grid>
      <StyledSeparator />
      <Grid item xs={12}>
        <StyledGridContent>
          <p>
            <span>PAYMENTS</span>
          </p>
          <p>
            1. Songtrust shall pay you Your Share of “Net Sums,” which, as used
            herein, shall mean all monies actually received by Songtrust in the
            United States which are directly attributable to the exploitation of
            Your Interest in the Compositions, less all actual, reasonable,
            non-overhead costs paid or incurred by Songtrust solely in
            connection with the exploitation of the Compositions and the
            collection of income, including, without limitation, any taxes
            required to be deducted, and payments to licensees outside the
            United States, if applicable (monies received by or credited to
            Songtrust directly attributable to exploitation of the Compositions
            in the United States shall be calculated “at source”). Songtrust
            shall retain the remaining Net Sums for its own account as its
            administration fee.
          </p>
          <p>
            2. In the event that Songtrust identifies that any overpayment or
            underpayment has been made to you in respect of Net Sums for any
            reason whatsoever (including but not limited to accounting or
            reporting errors, incorrect or inaccurate registration information,
            submission of Non-Qualified Works or in relation to any Illegitimate
            Activity) Songtrust retains the right to make an adjustment to Net
            Sums which adjustment shall be notified to you and applied from the
            next accounting date following the discovery of said overpayment or
            underpayment.
          </p>
          <p>
            3. In the event of any actual or alleged Illegitimate Activity with
            respect to your Compositions or in the event that Songtrust has, in
            its reasonable business judgment, reason to suspect that your
            Account has been subjected to and/or involved in Illegitimate
            Activity, in no way limiting Songtrust’s rights and remedies
            hereunder or otherwise at law or in equity, Songtrust may withhold
            any and all Net Sums due to you and to block your ability to
            otherwise withdraw funds from your account, for a reasonable period
            while Songtrust investigates the relevant activity and determines
            whether Illegitimate Activity has occurred. Upon Songtrust’s
            determination of Illegitimate Activity Songtrust will provide you
            with 30 days (reducible in Songtrust’s reasonable judgment, to
            protect or enforce Songtrust’s rights or interests) to
            satisfactorily show (in Songtrust’s absolute discretion) that the
            activity is not Illegitimate Activity failing which you agree that:
            (i) any Net Sums related to Illegitimate Activity shall be forfeited
            by you, and (ii) any damages and costs incurred by Songtrust
            (including, without limitation, amounts repaid to third party
            licensees and society and legal fees and costs) in connection
            therewith may, in addition to its other remedies, be deducted by
            Songtrust from any monies otherwise payable to you hereunder.
            Certain of Songtrust’s licensees may also have policies related to
            suspected or actual Illegitimate Activity and you agree that such
            policies shall be binding upon you hereunder.
          </p>
        </StyledGridContent>
      </Grid>
      <StyledSeparator />
      <Grid item xs={12}>
        <StyledGridContent>
          <p>
            <span>ACCOUNTING</span>
          </p>
          <p>
            While you will have daily access to your interim account information
            via your Account, formal accountings as to Net Sums payable by
            Songtrust to you hereunder shall be made to you via your Account on
            or before the first day of April for the period ending the preceding
            December 31st, and on or before the first day of July for the period
            ending March 31st, and on or before the first day of October for the
            period ending the preceding June 30th and on or before the first day
            of January for the period ending the preceding September 30th,
            together with payment of accrued Net Sums, if any, earned by you
            during such quarterly period. If the total Net Sums payable to you
            for any period do not exceed Twenty-Five U.S. Dollars ($25), then we
            may carry payment forward to the next accounting period in which the
            Net Sums payable to you exceed such sum. All statements and all
            other accountings rendered by Songtrust to you shall be binding upon
            you and not subject to any objection for any reason unless specific
            objection in writing, stating the Basis thereof is given to
            Songtrust within one (1) year from the date rendered. Statements and
            payments shall be sent in accordance with the relevant instructions
            in the Registration. No generalized objection (such as, but not
            limited to, a generalized claim of over-reporting of deductions or
            underreporting of income or any similar generalization) shall be
            deemed a valid objection.
          </p>
        </StyledGridContent>
      </Grid>
      <StyledSeparator />
      <Grid item xs={12}>
        <StyledGridContent>
          <p>
            <span>CANCELLATION</span>
          </p>
          <p>
            1. The Term and your enrollment in the Service will continue until
            you send a cancellation request at{' '}
            <HashLink
              to="https://www.songtrust.com/contact-support"
              style={hashLinkStyle}
            >
              https://www.songtrust.com/contact-support
            </HashLink>
            . Cancellation shall be effective at the end of the calendar quarter
            in which we receive such cancellation notice, subject to our
            post-Term Exploitation Period, post- Term and post-Exploitation
            Period collection rights and the rules and regulations of the
            relevant performance, licensing and other collection rights
            organizations and the full repayment of any sums due to Songtrust by
            you. Registration Fees are non-refundable once paid, and you are
            solely responsible for all charges, fees, duties, taxes, and
            assessments arising out of any use of your Account by you or anyone
            else using your Account.
          </p>
          <p>
            2. If you fail, or Songtrust suspects that you have failed, to
            comply with any of the provisions of this agreement, Songtrust, at
            its sole discretion, without notice to you may terminate this
            agreement and/or your Account, in which case you will remain liable
            for all amounts due under your Account up to and including the date
            of termination. We may also terminate the Term if we no longer
            provide the Services or for any other reason.
          </p>
          <p>
            3. In the event that there are any outstanding monies due by you to
            Songtrust upon termination of this agreement, Songtrust will provide
            you with an invoice for such amount, which shall be repaid by you
            within 14 days of receipt of an invoice. No termination of this
            agreement by you shall be valid until any and all sums due to
            Songtrust are repaid.
          </p>
          <p>
            4. Upon valid termination of your agreement Songtrust will no longer
            provide the Services, provided that we shall continue to have the
            right to collect all income payable in respect of exploitations of
            the Compositions that was earned during or before the Term and/or
            applicable Exploitation Period. Unless and until you or your new
            publishing administrator notifies all collection societies and other
            payment sources to update their records confirming the new
            administrator of your Compositions, Songtrust may continue to
            receive income from the Compositions as the most recent
            administrator of the Compositions. You hereby acknowledge and agree
            that Songtrust is not able to actively relinquish rights to
            Compositions and you will be fully responsible for the
            re-registration of Compositions after cancellation of the Services.
            In the event that you do not immediately re-register your
            Compositions with collection societies and other payment sources
            Songtrust may continue to receive income in respect of the
            Compositions. In this instance you will continue to receive residual
            accounting from us with respect to Your Share of any Net Sums in
            accordance with this agreement until such time as re-registrations
            are complete and no income is paid to Songtrust with respect to your
            Compositions. Songtrust shall continue to charge the Songtrust Fee
            in respect of such residual income as if the agreement were still in
            effect and otherwise in accordance with this agreement. You will
            retain access to your Account during this time and you are
            responsible for maintaining accurate and up-to-date contact and
            banking and tax information in your Account.
          </p>
        </StyledGridContent>
      </Grid>
      <StyledSeparator />
      <Grid item xs={12}>
        <StyledGridContent>
          <p>
            <span>CHANGES</span>
          </p>
          <p>
            Songtrust reserves the right at any time to modify this agreement
            and to impose new or additional terms or conditions on your use of
            the Service. We will inform you of any proposed modifications (e.g.,
            by e-mail or through your account page on the Site) and you may
            terminate the Term of this agreement if you do not wish to accept
            them. Otherwise, such modifications and additional terms and
            conditions will be deemed accepted and incorporated into this
            agreement.
          </p>
        </StyledGridContent>
      </Grid>
      <StyledSeparator />
      <Grid item xs={12}>
        <StyledGridContent>
          <p>
            <span>WARRANTIES AND INDEMNIFICATION</span>
          </p>
          <p>You hereby warrant and represent to Songtrust as follows:</p>
          <p>
            1. You are at least eighteen (18) years of age and, if you are
            entering into this agreement on your own behalf as a songwriter, are
            not currently signed to an exclusive songwriter, co-publishing,
            administration or other agreement regarding Your Interest in any
            Compositions or your songwriting services.
          </p>
          <p>
            2. All Registration information and other information you submit to
            Songtrust is and will remain truthful and accurate. You will notify
            us promptly if any information changes or needs to be updated. In
            the event we are put on notice with respect to a discrepancy or any
            inaccuracy with respect to information provided in the Registration,
            we shall have the right to suspend payments generated in connection
            with the Compositions in question until the discrepancy or
            inaccuracy is resolved to our reasonable satisfaction, without
            limitation of our indemnity rights as set forth below
          </p>
          <p>
            3. You have and shall continue to have the full right, capacity,
            power and authority to enter into and fully perform this agreement.
            Without limiting the foregoing, no consent of any third party is
            required, nor shall it be required, in order to effectuate the grant
            of rights made to Songtrust under this agreement, or Songtrust’s
            enjoyment of such rights and the proceeds thereof as contemplated
            hereunder.
          </p>
          <p>
            4. Neither the music, title, lyrics or other material comprising the
            Compositions nor any part thereof is or shall be a copy of any other
            copyrighted work or infringes or shall infringe upon any statutory
            or common law rights of any third party; or violates or shall
            violate any statutory or common law. Without limiting the foregoing,
            no Composition embodies a “sample,” “interpolation,” arrangement, or
            other portion of a musical composition owned or controlled by a
            third party. The copyright in the Compositions are either registered
            in the United States in your name or are otherwise eligible for
            copyright registration in the United States and protected and
            validly subsisting under applicable copyright law.
          </p>
          <p>
            5. The Compositions are and shall be free from any adverse claims,
            liens or encumbrances of any kind by any person or entity.
          </p>
          <p>
            6. None of the Compositions submitted hereunder are Non-Qualified
            Works.
          </p>
          <p>
            7. Neither you nor the Compositions are the subject of or otherwise
            involved in any Illegitimate Activity, and during the Term neither
            you nor your Compositions shall be the subject of or otherwise
            involved in any Illegitimate Activity.
          </p>
          <p>
            8. Songtrust shall not be required to make any payments of any
            nature for, or in connection with, the exploitation of the
            Compositions (including, without limitation, to any co-writers of
            the Compositions) except as specifically set forth herein. All
            co-writers, licensors, income participants and other third parties
            to whom you are obligated to pay a portion of the income from the
            Compositions, if any, shall look solely to you for any such
            payments.
          </p>
          <p>
            9. You will not abuse, interfere or attempt to interfere with the
            proper working of the Site or our Services or any transaction
            conducted on Site and will not take any action that imposes an
            unreasonable or disproportionately large load on the Site’s
            infrastructure.
          </p>
          <p>
            10. You have had the opportunity to consult with independent legal
            counsel in connection with this agreement.
          </p>
          <p>
            11. You shall at all times defend, indemnify and hold harmless
            Songtrust and its affiliates and their respective members,
            employees, affiliates, attorneys, representatives, agents, licensees
            and distributors (collectively, the &quot;Other Indemnitees&quot;)
            from and against any and all demands and/or claims by third parties
            and resulting damages, liabilities, losses, costs and expenses,
            including actual out-of-pocket legal expenses and reasonable counsel
            fees, arising out of any alleged breach or breach by you of any
            warranty, representation or agreement made herein, or in connection
            with any Illegitimate Activity involving you or your Compositions,
            or pertaining to any act, error or omission committed by you or any
            person or entity acting on your behalf (or on whose behalf you are
            acting) or under your direction or control. You will reimburse
            Songtrust and the Other Indemnitees, on demand, for any payment made
            at any time after the date hereof in respect of any liability or
            claim for which Songtrust or the Other Indemnitees are entitled to
            be indemnified, or Songtrust may elect to deduct any such payments
            from all sums otherwise due you hereunder. Pending the determination
            of any claim or action to which your indemnification obligations
            apply, Songtrust may withhold payments from all sums due to you in
            an amount reasonably related to such claim or action (including
            estimated attorneys’ fees and other costs). For the avoidance of
            doubt, Songtrust’s right to withhold payment of sums due to you
            hereunder is not intended as a limit on your indemnity obligations
            hereunder or as Songtrust’s sole or exclusive remedy or recourse. We
            reserve the right to assume the exclusive defense and control of any
            matter subject to indemnification obligations. You will cooperate as
            required by us and will not in any event settle any claim without
            our prior written consent.
          </p>
          <p>
            12. You will at all times during the term comply with laws
            pertaining to your use of the Services including but not limited to
            in relation to technology, software, intellectual property and
            services, all US export control laws and regulations (“Export
            Control Laws”) and all sanctions laws and regulations of all
            applicable jurisdictions governing trade and economic sanctions
            (“Sanctions Laws”). You must immediately notify Songtrust of any
            information that may indicate that there has been any violation or
            suspected violation of Export Control Laws or Sanctions Laws.
            Songtrust may cease performance of any of the Services (including
            suspending, withholding or ceasing payments to You) upon becoming
            aware of such a violation or suspected violation. Songtrust will not
            be liable for any action (or non-action) by Songtrust as a result of
            Songtrust complying with any Export Control Laws or Sanctions Laws
            or as determined by Songtrust as a result of any so-called ‘know
            your customer’ investigation on respect of You.
          </p>
        </StyledGridContent>
      </Grid>
      <StyledSeparator />
      <Grid item xs={12}>
        <StyledGridContent>
          <p>
            <span>
              DISPUTE RESOLUTION & MANDATORY ARBITRATION AND CLASS ACTION WAIVER
            </span>
          </p>
          <p>
            PLEASE READ THIS SECTION CAREFULLY, AS IT MAY IMPACT YOUR LEGAL
            RIGHTS, INCLUDING YOUR RIGHT TO FILE A LAWSUIT IN COURT OR AS PART
            OF A CLASS.
          </p>
          <p>
            1. <strong>Informal Dispute Resolution</strong>. Before bringing a
            formal action against Songtrust, you agree to try to resolve the
            dispute informally by sending us a written Notice of Dispute to
            legal@songtrust.com which includes a brief written statement with
            your name, address, and contact information you use for your
            Account, together with a brief description of the facts giving rise
            to the dispute and the relief requested. If the dispute is not
            resolved within 60 days of your submission of a Notice of Dispute,
            either party may bring a formal action in accordance with this
            agreement.
          </p>
          <p>
            2. <strong>Agreement to Arbitrate</strong>. Except as provided
            below, if we cannot resolve a dispute or claim informally, you and
            Songtrust agree to resolve any disputes or claims relating to or
            arising out of this agreement through final and binding arbitration
            on an individual basis. The arbitrator may award relief only
            individually and only to the extent necessary to redress your
            individual claim(s). The American Arbitration Association (AAA) will
            administer the arbitration under its Consumer Arbitration Rules. The
            AAA’s rules and filing instructions are available at{' '}
            <HashLink
              to="https://www.adr.org"
              target="_blank"
              style={hashLinkStyle}
            >
              www.adr.org
            </HashLink>{' '}
            or by calling 1-800-778-7879. This arbitration provision is governed
            by the Federal Arbitration Act. Any such arbitration will be held in
            the United States county where you live or work, New York, NY, or
            any other location we agree to.{' '}
            <strong>You and Songtrust expressly waive a trial by jury</strong>.
          </p>
          <p>
            3. <strong>Costs of Arbitration</strong>. The AAA rules will govern
            payment of all arbitration fees. For individual arbitration of
            non-frivolous claims less than $75,000 for which you timely provided
            Songtrust with a Notice of Dispute, Songtrust will reimburse
            arbitration filing fees at the conclusion of the arbitration and
            will pay other arbitration fees. For all other claims, the costs and
            fees of arbitration shall be allocated in accordance with the
            arbitration provider’s rules, including rules regarding frivolous or
            improper claims.{' '}
          </p>
          <p>
            4. <strong>Exceptions to Arbitration</strong>. Instead of using
            arbitration, either you or Songtrust may assert claims, if they
            qualify, in small claims court in New York, NY or any United States
            county where you live or work. You and Songtrust agree that where an
            arbitration is already initiated and one party invokes the small
            claims court option, any dispute regarding whether the dispute is
            properly within the jurisdiction of a small claims court shall be
            resolved by the small claims court in the first instance. The
            following claims don&apos;t have to be arbitrated and may be brought
            in court: disputes related to the enforcement of intellectual
            property (i.e., patents, copyrights and trademarks), efforts to
            interfere with the Services or engage with the Services in
            unauthorized ways (for example, Illegitimate Activities), and issues
            relating to the scope and enforceability of the arbitration
            agreement.
          </p>
          <p>
            5. <strong>Jurisdiction</strong>. For any claim that is not
            arbitrated or resolved in small claims court (in accordance with the
            terms of this agreement) or if the agreement to arbitrate is found
            not to apply to you or your claim, you agree that such claim will be
            resolved exclusively in state or federal court located in New York
            County, New York. You agree to submit to the personal jurisdiction
            of these courts for purposes of litigating any such claim.
          </p>
          <p>
            6. <strong>Opt-out of Agreement to Arbitrate</strong>. You may
            decline the agreement to arbitrate by sending written notice to
            opt-out to legal@songtrust.com and by mail to Legal Dept. ST Music
            LLC, 155 6th Avenue, Floor 15, New York, NY10013 within 30 days of
            first registering your Account. Your notice must include your name,
            address, contact information you use for your Account, and a clear
            statement that you want to opt out of this arbitration agreement. If
            you agreed to a previous version of this agreement that allowed you
            to opt out of arbitration, then your previous choice to opt out or
            not opt out remains binding. If you opt-out of the binding
            arbitration requirement, Songtrust also will not be bound by the
            requirement.
          </p>
          <p>
            7. <strong>Class Action Waiver</strong>. You may only resolve
            disputes with us on an individual basis and may not bring a claim as
            a plaintiff or a class member in a class, consolidated, or
            representative action. Class arbitrations, class actions, private
            attorney general actions, and consolidation with other arbitrations
            aren’t allowed.
          </p>
          <p>
            8. <strong>Severability</strong>. If any part of this arbitration
            agreement is found to be illegal or unenforceable, the remainder
            will remain in effect, except that if a finding of partial
            illegality or unenforceability would allow class or representative
            arbitration, this arbitration agreement will be unenforceable in its
            entirety. If you are found to have a non-waivable right to bring a
            particular claim or to request a particular form of relief that the
            arbitrator lacks authority to redress or award according to this
            arbitration agreement, including public injunctive relief, then only
            that respective claim or request for relief may be brought in court,
            and you and we agree that litigation of any such claim or request
            for relief shall be stayed pending the resolution of any individual
            claim(s) or request(s) for relief in arbitration.
          </p>
        </StyledGridContent>
      </Grid>
      <StyledSeparator />
      <Grid item xs={12}>
        <StyledGridContent>
          <p>
            <span>MISCELLANEOUS</span>
          </p>
          <p>
            IN NO EVENT WILL SONGTRUST BE LIABLE TO YOU OR ANY THIRD PERSON FOR
            ANY INDIRECT, CONSEQUENTIAL, EXEMPLARY, INCIDENTAL, SPECIAL OR
            PUNITIVE DAMAGES, INCLUDING DAMAGES FOR ANY LOST PROFITS OR LOST
            DATA ARISING FROM YOUR USE OF THE SITE OR THE SERVICE, EVEN IF
            SONGTRUST IS AWARE OR HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH
            DAMAGES. NOTWITHSTANDING ANYTHING TO THE CONTRARY CONTAINED HEREIN,
            SONGTRUST&apos;S LIABILITY TO YOU FOR ANY CAUSE WHATSOEVER, AND
            REGARDLESS OF THE FORM OF THE ACTION, WILL AT ALL TIMES BE LIMITED
            TO THE AMOUNT PAID, IF ANY, BY YOU TO SONGTRUST FOR THE SERVICES.
            THE SITE, THE SERVICES AND THE CONTENT RELATED THERETO ARE PROVIDED
            &quot;AS-IS&quot; AND SONGTRUST DISCLAIMS ANY AND ALL WARRANTIES,
            WHETHER EXPRESS OR IMPLIED, INCLUDING WITHOUT LIMITATION IMPLIED
            WARRANTIES OF TITLE, MERCHANTABILITY, FITNESS FOR A PARTICULAR
            PURPOSE OR NON- INFRINGEMENT. SONGTRUST CANNOT GUARANTEE AND DOES
            NOT PROMISE ANY REVENUE OR OTHER SPECIFIC RESULTS FROM USE OF THE
            SITE AND/OR THE SERVICES. TO THE EXTENT THESE DISCLAIMERS AND
            LIMITATIONS ARE LIMITED BY APPLICABLE LAW, THEY SHALL OTHERWISE
            APPLY TO THE FULLEST EXTENT OF SUCH LAW.
          </p>
          <p>
            This agreement, together with the general terms of service for the
            Songtrust website (
            <HashLink
              to="https://songtrust.com/"
              target="_blank"
              style={hashLinkStyle}
            >
              located here
            </HashLink>
            ), our Privacy Policy (
            <HashLink
              target="_blank"
              to="https://www.songtrust.com/privacy-policy"
              style={hashLinkStyle}
            >
              located here
            </HashLink>
            ) (which outlines our practices towards handling any personal
            information you may provide to us) and the Registration, sets forth
            the entire understanding between Songtrust and you regarding the
            Site and Services. We may assign or delegate this agreement or any
            or all of our rights and obligations hereunder to one or more third
            parties without notice to you. No waiver of any provision or default
            under this agreement shall affect either party’s rights thereafter
            and no waiver by either party shall be deemed a continuing waiver.
            Except as expressly set forth herein, neither party has made or
            makes any representations or warranties, express or implied, with
            respect to any matter contained in this agreement or with respect to
            the making of this agreement, all of which are expressly disclaimed.
            If any clause, sentence, paragraph or part of this agreement or the
            application thereof to any person, shall for any reason be adjudged
            by a court of competent jurisdiction to be invalid, such judgment
            shall be limited and confined in its operation to the clause,
            sentence, paragraph or part thereof directly involved in the
            controversy and shall not affect the remainder of the agreement.
          </p>
          <p>
            You acknowledge that any exploitation of the Compositions is
            speculative and that Songtrust cannot guarantee that the
            Compositions will be exploited at all or that any Net Sums will be
            generated or earned hereunder. You waive all claims and warrant,
            represent and agree that you will not make any claim, nor will any
            liability be imposed upon Songtrust based upon a claim, that more
            Net Sums could have been generated or better business achieved than
            that which was actually generated or achieved by Songtrust and/or
            its licensees.
          </p>
          <p>
            Songtrust does not guarantee, represent, or warrant that your use of
            the Site or Services will be uninterrupted or error-free, and you
            agree that from time-to-time Songtrust may suspend the Site and
            Services for indefinite periods of time for technical maintenance or
            upgrade or otherwise, or may cancel the service at any time, without
            notice to you. The functions and features of the Site and Service
            are subject to change without notice.
          </p>
          <p>
            Your use of the Site and Service includes the ability to enter into
            agreements and/or to make transactions electronically. YOU
            ACKNOWLEDGE THAT YOUR REGISTRATION AND ELECTRONIC SUBMISSIONS
            CONSTITUTE YOUR AGREEMENT AND INTENT TO BE BOUND BY AND TO PAY FOR
            THE SERVICES. YOUR AGREEMENT AND INTENT TO BE BOUND BY ELECTRONIC
            SUBMISSIONS APPLIES TO ALL RECORDS RELATING TO ALL TRANSACTIONS YOU
            ENTER INTO ON THIS SITE, INCLUDING NOTICES OF CANCELLATION,
            POLICIES, CONTRACTS, AND APPLICATIONS.
          </p>
          <p>
            Songtrust®, the Songtrust logo, and other Songtrust trademarks,
            service marks, graphics, and logos used in connection with the
            Service are trademarks or registered trademarks of ST Music LLC in
            the U.S. and/or other countries. Any other trade- or service marks
            referenced belong to their respective owners. You are granted no
            right or license with respect to any of the foregoing.
          </p>
          <p>
            This agreement is entered into in the State of New York, U.S.A., and
            shall be construed in accordance with the laws of said state
            applicable to contracts to be wholly performed therein, without
            reference to any conflict of laws principles.
          </p>
        </StyledGridContent>
      </Grid>
      <StyledSeparator />
      <Grid item xs={12} md={12}>
        <StyledGridContent>
          <p>
            <span>DEFINITIONS</span>
          </p>
          <p>
            <em id="exploitation-period">“Exploitation Period</em>” for a
            Composition shall mean the later of (a) the end of the Term or (b)
            one (1) year after you submit the same to us. For example, if you
            submit a Composition on January 1st and the Term ends on February
            1st of that year, then the Exploitation Period for that Composition
            shall continue until December 31st of that year.
          </p>
          <p>
            <em id="illegitimate-activity">“Illegitimate Activity</em>” shall
            mean any infringement of third-party rights, so called fraudulent
            activity (which for clarity includes submission of Non-Qualified
            Works for administration hereunder, streaming fraud, and
            artificially inflated or manipulated streaming activity) or similar
            activity in contravention of the terms of use of any digital service
            provider, PRO or CMO, or any illegal or other activities in
            violation of this agreement.
          </p>
          <p>
            <em id="non-qualified-work">“Non-Qualified Works”</em> shall mean:
            (i) works that are non-musical in nature, (ii) works that consist
            substantially of sound effects, so-called “sleep sounds” or “nature
            sounds”, (iii) spoken word works, (iv) works that are in the public
            domain and (v) any other works not accepted by any of Songtrust’s
            third party partners or societies as qualifying musical works.
          </p>
          <p>
            <em id="registration">“Registration”</em> shall mean the online
            registration that you are required to complete in order to become a
            Songtrust member, and the registration of Your Interest in the
            Compositions with the relevant performance rights organizations
            (e.g., ASCAP or BMI) and other licensing agencies (e.g., The Harry
            Fox Agency, Mechanical Licensing Collective), and acceptance of same
            by such performance rights organization and agencies, in accordance
            with their then current rules, terms and conditions.
          </p>
          <p>
            <em id="services">“Services”</em> shall mean the music publishing
            administration services provided to you by Songtrust in each
            applicable Territory as selected by you during Registration (or
            updated by you from time to time during the Term via the Site).
          </p>
          <p>
            <em id="site">“Site”</em> shall mean songtrust.com,
            blog.songtrust.com and any and subdomains and other Songtrust-
            branded web-based properties (and mobile apps or other mobile
            versions of same) owned and operated by Songtrust.
          </p>
          <p>
            <em id="term">“Term”</em> shall commence upon the date of your
            registration (your submission of a complete Registration and payment
            of the applicable registration fee) and shall continue (a) through
            the end of the calendar quarter during which we receive your valid
            cancellation notice in accordance with the provisions in the
            “Cancellation” section above or (b) until we terminate the Term as
            provided above.
          </p>
          <p>
            <em id="territory">“Territory”</em> shall mean the universe.
          </p>
          <p>
            <em id="your-share">“Your Share”</em> shall mean: (i) eighty-five
            percent (85%) of Net Sums comprising the so-called “publishers
            share” of public performance royalties; and (ii) eighty-percent
            (80%) of Net Sums derived from the sale, lease, license or other
            disposition of mechanical rights, print licenses, synchronization
            licenses (if applicable) or any other exploitation of the
            Compositions not specifically set forth herein (other than public
            performance rights).
          </p>
        </StyledGridContent>
      </Grid>
    </Grid>
  );
}

export default TermsPageContent;
