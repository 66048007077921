import { useQuery, useReactiveVar } from '@apollo/client';
import { Container, Grid, Typography } from '@mui/material';
import { Box } from '@mui/system';
import _ from 'lodash';
import { useState } from 'react';
import LoadingIndicator from '~/base/components/LoadingIndicator';
import PageTitle from '~/base/components/PageTitle/PageTitle';
import SectionTipCard from '~/base/components/SectionTipCard';
import FlagTypeEnum from '~/base/enums/flagTypeEnum';
import SwitchNameEnum from '~/base/enums/switchNameEnum';
import useFeatureFlag from '~/base/hooks/useFeatureFlag';
import { currentSongtrustUserPersonaVar, dashboardResponseVar } from '~/cache';
import usePageTitle from '~/hooks/usePageTitle';
import { Translator } from '~/types/Translator';
import {
  AccountCompletionStep,
  DashboardDataDocument,
  DashboardDataQuery,
  DashboardItemType,
  Period,
  PeriodEdge,
} from '~/types/generated/graphql';
import DashboardAccountCompletion from '../DashboardAccountCompletion';
import DashboardItem from '../DashboardItem';
import DashboardMfaItem from '../DashboardMfaItem/DashboardMfaItem';
import DashboardMyEarnings from '../DashboardMyEarnings';

function DashboardPage({ t }: Translator) {
  usePageTitle(t('page.title'));

  const [accountComplete, setAccountComplete] = useState<boolean>(false);

  const [startQuarter, setStartQuarter] = useState<Date | undefined>(undefined);
  const [endQuarter, setEndQuarter] = useState<Date | undefined>(undefined);

  const { loading, data } = useQuery<DashboardDataQuery>(
    DashboardDataDocument,
    {
      fetchPolicy: 'cache-and-network',
      onCompleted: (res) => {
        dashboardResponseVar(res);

        // Set start and end quarters for earnings query.
        const periodArray = res?.periods?.edges as Array<PeriodEdge>;
        if (periodArray !== undefined) {
          setStartQuarter((_.head(periodArray)?.node as Period)?.endDate);
          setEndQuarter((_.last(periodArray)?.node as Period)?.endDate);
        }

        // Set completion status for rendering completion vs earnings.
        const totalSteps = (
          res?.accountCompletion?.steps as Array<AccountCompletionStep>
        ).length;
        const totalCompletedSteps = _.filter(
          res?.accountCompletion?.steps as Array<AccountCompletionStep>,
          (item) => {
            return item.isCompleted;
          },
        ).length;
        if (totalSteps === totalCompletedSteps) {
          setAccountComplete(true);
        }
      },
      onError: () => {
        dashboardResponseVar(undefined);
      },
    },
  );

  const loggedInUser = useReactiveVar(currentSongtrustUserPersonaVar);
  const userOptedInMfa = loggedInUser?.loggedInSongtrustUser?.mfaOptIn === true;

  const { hasFlag: mfaSwitchOn } = useFeatureFlag(
    SwitchNameEnum.MFA,
    FlagTypeEnum.SWITCH,
  );

  return (
    <div data-testid="dashboard-page">
      <Container>
        <Grid
          container
          sx={{
            flexDirection: {
              xs: 'column',
            },
          }}
        >
          <PageTitle
            pageTitle={{
              id: 'dashboard-page-title',
              title: t('page.title'),
            }}
            pageSubtitle={{
              subtitle: t('page.subtitle'),
            }}
          />
          <Grid
            container
            sx={{
              flexDirection: {
                xs: 'column-reverse',
                md: 'row',
              },
              pt: '3rem',
            }}
          >
            <Grid item xs={12} md={9}>
              <Box
                data-testid="dashboard-content"
                sx={{
                  pl: { md: '2rem' },
                  pr: { md: '4rem' },
                  pt: { xs: '2rem', md: '0' },
                }}
              >
                <Grid container>
                  {loading && <LoadingIndicator size={50} />}
                  {!loading &&
                    data &&
                    data.newsAndEvents &&
                    data.newsAndEvents.edges && (
                      <Grid item xs={12}>
                        {data.newsAndEvents.edges.map((newsItem) => {
                          return (
                            <DashboardItem
                              t={t}
                              key={`item_${newsItem?.node?.id}`}
                              item={newsItem?.node as DashboardItemType}
                            />
                          );
                        })}
                      </Grid>
                    )}
                </Grid>
              </Box>
            </Grid>
            <Grid item xs={12} md={3} sx={{ mt: { md: '1.5rem' } }}>
              <Grid container rowSpacing={2}>
                {accountComplete && (
                  <Grid item xs={12}>
                    <DashboardMyEarnings
                      t={t}
                      startQuarter={startQuarter}
                      endQuarter={endQuarter}
                    />
                  </Grid>
                )}

                {!accountComplete && (
                  <Grid item xs={12}>
                    {loading && <LoadingIndicator size={50} />}
                    {!loading &&
                      data &&
                      data.accountCompletion &&
                      data.accountCompletion.steps && (
                        <DashboardAccountCompletion
                          t={t}
                          items={
                            data.accountCompletion
                              .steps as Array<AccountCompletionStep>
                          }
                        />
                      )}
                  </Grid>
                )}

                {mfaSwitchOn && loading && <LoadingIndicator size={50} />}
                {mfaSwitchOn && !loading && !userOptedInMfa && (
                  <Grid item xs={12} sx={{ mt: '1.5rem' }}>
                    <DashboardMfaItem t={t} />
                  </Grid>
                )}

                <Grid item xs={12}>
                  {loading && <LoadingIndicator size={50} />}
                  {!loading && data && data.randomTip && (
                    <SectionTipCard title={data.randomTip?.title as string}>
                      <Typography
                        variant="body1"
                        component="span"
                        dangerouslySetInnerHTML={{
                          __html: data.randomTip?.content as string,
                        }}
                      />
                    </SectionTipCard>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}

export default DashboardPage;
